<template>
<svg class="icon">
    <use :xlink:href="`#${icon}`" />
</svg>
</template>

<script>
export default {
    name: "Icon",
    props: {
        icon: {
            type: String,
            required: true
        }
    }
}
</script>

<style lang="scss">
.icon {
    display: var(--icon-display, inline-block);
    width: var(--icon-width, var(--icon-size, 24px));
    height: var(--icon-height, var(--icon-size, 24px));
    fill: var(--icon-color, currentColor);
}
</style>
