import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Home",
        meta: {
            title: "СРО Единство",
            requiresAuth: true
        },
        component: Home
    },
    {
        path: "/login",
        name: "Login",
        meta: {
            title: "Вход",
            requiresGuest: true
        },
        component: () => import(/* webpackChunkName: "login" */ "@/views/Login.vue")
    },
    {
        path: "/meeting",
        name: "Meeting",
        meta: {
            title: "Собрание",
            requiresAdmin: true
        },
        component: () => import(/* webpackChunkName: "meeting" */ "@/views/Meeting.vue")
    },
    {
        path: "/survey",
        name: "Survey",
        meta: {
            title: "Собрание"
        },
        component: () => import(/* webpackChunkName: "survey" */ "@/views/Survey.vue")
    },
    {
        path: "/users",
        name: "Users",
        meta: {
            title: "Пользователи"
        },
        component: () => import(/* webpackChunkName: "users" */ "@/views/Users.vue")
    },
    {
        path: "/meetings/:meetingId",
        name: "MeetingItem",
        meta: {
            title: "Результаты собрания",
            requiresAuth: true,
        },
        component: () => import(/* webpackChunkName: "meeting-item" */ "@/views/MeetingItem.vue"),
        props: true,
    },
    {
        path: "/case/:caseId",
        name: "CaseItem",
        meta: {
            title: "Имущество дела",
            requiresAuth: true,
        },
        component: () => import(/* webpackChunkName: "case-item" */ "@/views/CaseItem.vue"),
        props: true,
    },
    {
        path: "/case/:caseId/property/create",
        name: "PropertyCreation",
        meta: {
            title: "Добавление имущества",
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "property-creation" */ "@/views/PropertyCreation.vue"),
        props: true,
    },
    {
        path: "/case/:caseId/property/:propertyId",
        name: "PropertyItem",
        meta: {
            title: "Имущество",
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "property-item" */ "@/views/PropertyItem.vue"),
        props: true,
    },
    {
        path: "/401",
        name: "Error401",
        meta: {
            title: "Ошибка 401",
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "error-401" */ "@/views/Error401.vue"),
        props: true
    },
    {
        path: "/404",
        name: "Error404",
        meta: {
            title: "Ошибка 404"
        },
        component: () => import(/* webpackChunkName: "error-404" */ "@/views/Error404.vue")
    },
    {
        path: "*",
        redirect: "/404"
    }
]

const router = new VueRouter({
    mode: "history",
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        }

        if (to.hash) {
            return {
                selector: to.hash
            }
        }

        return { x: 0, y: 0 };
    }
});

router.beforeEach((to, from, next) => {
    if (to.meta.requiresAuth) {
        if (store.getters.isAuthenticated) {
            next();
            return;
        } else {
            next("/login");
        }

    } else {
        next();
    }
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAdmin)) {
        if (store.getters.isAdmin) {
            next();
            return;
        } else {
            next({
                name: "Error401",
                params: {
                    requestedResource: to.meta.title
                }
            });
        }
    } else {
        next();
    }
});

router.beforeEach((to, from, next) => {
    if (to.meta.requiresGuest) {
        if (store.getters.isAuthenticated) {
            next("/");
            return;
        } else {
            next();
        }

    } else {
        next();
    }
});

router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        store.commit("setTitle", to.meta.title);
    }

    next();
});

export default router;
