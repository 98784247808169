var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"header"},[_c('div',{staticClass:"header__container container"},[_c('router-link',{staticClass:"header__brand",attrs:{"to":{ name: 'Home' }}},[_c('img',{staticClass:"header__logo",attrs:{"src":"/img/logo.png","alt":"Логотип СРО «Единство»","width":"54","height":"54"}}),_c('span',{staticClass:"header__title"},[_vm._v("Единство")])]),(_vm.isAuthenticated && _vm.user.admin)?_c('div',{staticClass:"header__admin"},[_c('router-link',{staticClass:"header__admin-link",attrs:{"to":{ name: 'Home' },"exactActiveClass":"header__admin-link_active"}},[_vm._v(" Собрания ")]),_c('router-link',{staticClass:"header__admin-link",attrs:{"to":{ name: 'Users' },"exactActiveClass":"header__admin-link_active"}},[_vm._v(" Пользователи ")])],1):_vm._e(),(_vm.isAuthenticated)?_c('div',{staticClass:"header__user"},[_c('popper',{staticClass:"header__notifications",attrs:{"trigger":"clickToOpen","options":{
                    placement: 'bottom',
                    modifiers: { offset: { offset: '-120px, 5px' } }
                }}},[_c('button',{staticClass:"header__notifications-button",attrs:{"slot":"reference"},slot:"reference"},[_c('icon',{staticClass:"header__notifications-icon",attrs:{"icon":"bell"}})],1),_c('div',{staticClass:"header__popup header__popup_notifications"},[_c('p',[_vm._v("Новых уведомлений нет")])])]),_c('popper',{staticClass:"header__user-profile",attrs:{"trigger":"clickToOpen","options":{
                    placement: 'bottom',
                    modifiers: { offset: { offset: '0, 5px' } }
                }}},[_c('img',{staticClass:"header__user-img",attrs:{"slot":"reference","src":_vm.user.img,"alt":_vm.user.short,"width":"48","height":"48"},slot:"reference"}),_c('div',{staticClass:"header__popup header__popup_user"},[_c('span',[_vm._v(_vm._s(_vm.user.short))]),_c('button',{staticClass:"header__user-logout",on:{"click":_vm.logout}},[_vm._v("Выйти")])])])],1):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }