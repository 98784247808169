<template>
    <div class="error-message">
        <p class="error-message__heading">
            <slot>Ошибка загрузки данных.</slot>
        </p>
        <button class="error-message__reload" @click="reload">Обновить страницу</button>
    </div>
</template>

<script>
    export default{
        name: "ErrorMessage",
        methods: {
            reload() {
                this.$router.go();
            }
        }
    }
</script>

<style lang="scss">
    .error-message {
        display: grid;
        gap: 16px;
    }
    .error-message__heading {
        color: var(--red-error);
        font-size: 20px;
        font-weight: 500;
    }
    .error-message__reload {
        color: var(--green-200);
        text-decoration: underline;

        transition: color 100ms ease-in-out;

        &:hover {
            color: var(--black);
        }
    }
</style>
