<template>
<main class="cases">
    <div class="cases__container container">
        <header class="cases__header">
            <h1 class="cases__heading">Дела</h1>
            <form class="cases__search-form" @submit.prevent="onCaseSearch">
                <label class="control cases__search-field">
                    <span class="control__field">
                        <icon class="control__icon" icon="search"/>
                        <input class="control__input" type="text" placeholder="Поиск дела" v-model="searchInput">
                        <span class="control__label">Поиск дела</span>
                        <button class="control__clear-search" @click="clearSearch" type="button">
                            <icon class="control__clear-search-icon" icon="close"/>
                        </button>
                    </span>
                </label>
                <button class="cases__search-button button">Найти</button>
            </form>
        </header>
        <div class="cases__content">
            <error-message class="cases__error-message" v-if="status === 'error'">
                Не удалось загрузить данные
            </error-message>
            <div class="cases__data" v-else>

                <spinner class="cases__spinner" v-if="status === 'loading'"/>
                <div class="cases__view" v-else-if="status === 'success'">
                    <div class="cases__table-wrapper" ref="casesBox">
                        <table class="cases__table" v-if="casesList.length > 0">
                            <thead>
                                <tr class="cases__table-row">
                                    <th class="cases__table-cell cases__table-cell_head"
                                        v-for="(caseFieldData, caseFieldName) in caseFields" :key="caseFieldName"
                                    >
                                        {{ caseFieldData.title }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="cases__table-row cases__table-row_body" v-for="case_item in casesList" :key="case_item.id">
                                    <td class="cases__table-cell cases__table-cell_body"
                                        v-for="(caseFieldData, caseFieldName) in caseFields" :key="caseFieldName"
                                        @click.prevent="handleCaseClick(case_item.id)"
                                    >
                                        {{ case_item[caseFieldName] }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <p v-else style="text-align: center; margin-top: 20px;">Дела не найдены</p>
                    </div>
                    <pagination class="cases__pagination"
                        v-if="casesPagesTotalCount > 1"
                        v-model="ordersPageModel"
                        :total="casesPagesTotalCount"
                    />
                </div>
            </div>
        </div>
    </div>
    <div class="cases__notification" v-if="currentMeeting && isUserInMeeting && userNotAnswered">
        <div class="cases__notification-info">
            <h2 class="cases__notification-title">Пройдите собрание</h2>
            <p class="cases__notification-text">Необходимо пройти собрание "{{ currentMeeting.short }}"</p>
        </div>
        <router-link :to="{ name: 'Survey' }"
            class="button cases__notification-link"
        >
            Пройти
        </router-link>
    </div>
</main>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { dateTextLong } from "@/helpers/dates";
import Pagination from "@/components/Pagination";

export default {
    name: "Cases",
    components: {
        Pagination
    },
    filters: {
        dateTextLong
    },
    data() {
        return {
            status: "loading",
            searchInput: "",
            searchTerm: "",
            caseFields: {
                OWNER: {
                    title: "Владелец"
                },
                NAME: {
                    title: "Наименование"
                },
                CASE_STATUS: {
                    title: "СтатусДела"
                },
                COURT: {
                    title: "Суд"
                },
                PROCEDURE_TYPE: {
                    title: "Процедура"
                },
            }
        }
    },
    computed: {
        ...mapState({
            currentMeeting: state => state.meetings?.current,
            currentUser: state => state.auth?.user,
            casesBySearch: state => state.cases?.casesBySearch,
        }),
        searchKey() {
            if (this.searchTerm !== "") {
                return this.searchTerm.trim().toLowerCase();
            }

            return "default";
        },
        cases() {
            return this.casesBySearch?.[this.searchKey];
        },
        casesList() {
            return this.cases?.list ?? [];
        },
        casesCurrentPage() {
            return this.cases?.currentPage + 1;
        },
        casesPagesTotalCount() {
            return this.cases?.pagesTotalCount;
        },
        isUserInMeeting() {
            return this.currentMeeting?.users?.find(user => user.id_user == this.currentUser.id);
        },
        userNotAnswered() {
            return !this.currentMeeting?.users?.find(user => user.id_user == this.currentUser.id)?.is_respond;
        },
        ordersPageModel: {
            get() {
                return this.casesCurrentPage;
            },
            set(casesCurrentPage) {
                this.setCasesPageBySearch({
                    searchKey: this.searchKey,
                    currentPage: casesCurrentPage - 1
                });

                this.loadCasesPage();
            }
        },
    },
    methods: {
        ...mapMutations([
            "setCasesPageBySearch",
            "setCasesBySearchDefaults",
        ]),
        ...mapActions([
            "requestCurrentMeeting",
            "requestCases",
        ]),
        showData() {
            this.status = "success";
        },
        showLoading() {
            this.status = "loading";
        },
        showError() {
            this.status = "error";
        },
        loadData() {
            return Promise.all([
                this.requestCurrentMeeting(),
                this.requestCases({
                    searchTerm: this.searchTerm,
                    searchKey: this.searchKey,
                }),
                new Promise(resolve => setTimeout(resolve, 250))
            ]);
        },
        loadCasesPage() {
            this.$refs.casesBox.scrollTop = 0;
            this.showLoading();

            Promise.all([
                this.requestCases({
                    searchTerm: this.searchTerm,
                    searchKey: this.searchKey,
                }),
                new Promise(resolve => setTimeout(resolve, 250))
            ]).then(this.showData, this.showError);
        },
        handleCaseClick(id) {
            this.$router.push({
                name: "CaseItem",
                params:{ caseId: id }
            });
        },
        clearSearch() {
            if (this.searchTerm !== "") {
                this.showLoading();

                this.searchTerm = this.searchInput = "";

                this.setCasesPageBySearch({
                    searchKey: this.searchKey,
                    currentPage: 0
                });

                this.loadCasesPage();
            }
        },
        onCaseSearch() {
            this.showLoading();
            this.searchTerm = this.searchInput;

            if (!this.cases) {
                this.setCasesBySearchDefaults(this.searchKey);
            }

            this.setCasesPageBySearch({
                searchKey: this.searchKey,
                currentPage: 0
            });

            this.loadCasesPage();
        }
    },
    created() {
        if (this.currentMeeting && this.casesList?.length !== 0) {
            this.showData();
        } else {
            this.loadData().then(this.showData, this.showError);
        }
    }
}
</script>

<style lang="scss">
.cases {
    height: calc(100% - var(--header-height));
    padding: 12px 0 16px;
    overflow: hidden;
}
.cases__container {
    height: 100%;
    display: grid;
    grid-template-rows: auto 1fr;
    gap: 12px;
}
.cases__heading {
    font-family: var(--garamond);
    font-weight: 500;
    font-size: 36px;
    line-height: 1;
}
.cases__content  {
    display: grid;
    background-color: var(--white);
    border-radius: var(--border-radius);
    overflow: hidden;
    padding: 12px 24px 16px;
}
.cases__data {
    display: grid;
    overflow: hidden;
}
.cases__spinner {
    place-self: center;
}
.cases__error-message {
    place-self: center;
}
.cases__view {
    display: grid;
    grid-template-rows: 1fr auto;
    align-content: start;
    grid-gap: 12px;
    overflow: hidden;
}
.cases__table-wrapper {
    padding: 0 4px 4px 4px;
    overflow: auto;

    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: transparent;
    }
    &::-webkit-scrollbar-track {
        border-width: 0;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 6px;
        background-color: var(--gray-400);
    }
}
.cases__table {
    width: 100%;
}
.cases__table-row {
    cursor: pointer;

    &:nth-of-type(odd) {
        background-color: var(--gray-200);
    }

    &_body {
        border-radius: var(--border-radius);
        transition: transform 150ms ease-in-out;

        &:hover {
            // transform: translateY(4px);
            outline: 2px solid var(--green-primary);
            outline-offset: -2px;
        }
    }
}
.cases__table-cell {
    font-size: 14px;
    padding: 14px 8px;
    text-align: left;

    &_head {
        background-color: var(--white);
        font-weight: normal;
        font-size: 13px;
        color: var(--gray-400);
        padding: 4px 8px 8px;

        position: sticky;
        top: 0;
        z-index: 1;
    }

    &_body {
        white-space: nowrap;
    }
}
.cases__notification {
    width: 530px;
    border-radius: calc(2 * var(--border-radius));
    padding: 24px;

    color: var(--white);

    position: fixed;
    left: 50%;
    bottom: 20px;
    background-color: var(--green-primary);
    transform: translateX(-50%);
}
.cases__notification-title {
    font-weight: 500;
    font-size: 24px;
}
.cases__notification {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    gap: 10px;
}
.cases__notification-link {
    &:hover {
        background-color: #60a476;
    }
}
.cases__pagination {
    justify-self: center;
}
.cases__search-form {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 12px;

    @include from-desktop {
        width: 600px;
    }

    .control__field {
        padding-top: 6px;
        padding-bottom: 6px;
    }
}
.cases__header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
}
.cases__search-button {
    padding-top: 12px;
    padding-bottom: 12px;
}
</style>
