import Vue from "vue";
import axios from "axios";
import parseISO from "date-fns/parseISO";

function caseItemInitialState() {
    return {
        ids: [],
        byId: {},
    };
}

export default {
    state: () => caseItemInitialState(),
    mutations: {
        setCasesParameter(state, { parameter, value }) {
            state[parameter] = value;
        },
        setCaseItem(state, { case_list }) {
            let caseItem = case_list[0];

            state.ids.push = caseItem.id;
            Vue.set(state.byId, caseItem.id, caseItem);
        },
        resetCaseItemState(state) {
            Object.assign(state, caseItemInitialState());
        },
        setCaseFiles(state, { case_id, data }) {
            state.ids.push = caseItem.id;
            Vue.set(state.byId, caseItem.id, caseItem);
        },
        deletePropertyFromState(state, { caseId, id: propertyToDeleteId }) {
            state.byId?.[caseId].INK_PROPERTY.filter(({ id }) => id != propertyToDeleteId);
        },
    },
    actions: {
        requestCaseItem: ({ commit, state, rootState }, { case_id }) => axios.get(`/case/get/0`, {
            params: {
                user_id: rootState.auth.user.id,
                case_id
            }
        }).then(
            ({ data }) => {
                data.case_list.forEach(case_item => {
                    [
                        "PROTOCOL_DATE",
                        "LETTER_DATE",
                        "COURT_DATE_SK",
                        "K_BALANCE_DATE",
                        "CHANGE_PROCEDURE",
                    ].forEach(field => {
                        let date = parseISO(case_item[field]);
                        case_item[field] = date.getFullYear() === 1 ? null : date;
                    });



                    if (case_item?.INK_PROPERTY?.length > 0) {
                        let propertyDateFields = [
                            "PROPERTY_K_INVENTORY_DATE",
                            "PROPERTY_K_TRANSFER_EVALUATION_DATE",
                            "PROPERTY_K_AUCTION_DATE",
                            "PROPERTY_K_REPORT_DATE",
                            "PROPERTY_K_PLANE_PUTTING_UP_DATE",
                        ];

                        case_item?.INK_PROPERTY.forEach(property => {
                            propertyDateFields.forEach(field => {
                                if (property[field] != null) {
                                    let date = parseISO(property[field]);
                                    property[field] = date.getFullYear() === 1 ? null : date;
                                }
                            });
                        });
                    }
                });

                commit("setCaseItem", data);
            }
        ),
        updateCasePropertyInDB: ({ commit }, propertyData) => axios.post("/property/set", propertyData),
        createCasePropertyInDB: ({ commit }, propertyData) => axios.post("/property/create", propertyData),
        uploadCaseFiles: ({ commit }, formData) => axios.post("/case/add_files", formData).then(
            ({ data }) => {
                // commit("setCaseFiles", { case_id: formData.case_id, data });
            }
        ),
        uploadPropertyFiles: ({ commit }, formData) => axios.post("/property/add_files", formData).then(
            ({ data }) => {
                // commit("setCaseFiles", { case_id: formData.case_id, data });
            }
        ),
        deletePropertyFromDB: ({ commit }, { caseId, id}) => axios.post("/property/delete", { id }).then(
            () => commit("deletePropertyFromState", { caseId, id })
        ),
    }
};
