<template>
<button class="button">
    <icon v-if="icon" class="button__icon" :icon="icon"/>
    <slot/>
</button>
</template>

<script>
export default {
    name: "SroButton",
    props: {
        icon: {
            type: String
        },
    },
    data() {
        return {};
    },
    computed: {},
    methods: {}
}
</script>

<style lang="scss">

</style>
