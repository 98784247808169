import Vue from "vue";
import axios from "axios";

function meetingsInitialState() {
    return {
        current: null,
        currentResulstExcelLink: null,
        all: []
    };
}

export default {
    state: () => meetingsInitialState(),
    mutations: {
        setAllMeetings(state, { current, all }) {
            Vue.set(state, "current", current);
            Vue.set(state, "all", all);
        },
        setCurrentMeeting(state, { current }) {
            Vue.set(state, "current", current);
        },
        setCurrentMeetingResulstExcelLink(state, { file }) {
            state.currentResulstExcelLink = `${process.env.VUE_APP_API_URL}/${file}`;
        },
        resetMeetingsState(state) {
            Object.assign(state, meetingsInitialState());
        },
        setMeetingClose(state) {
            state.current = null;
            state.currentResulstExcelLink = null;
            state.all.forEach(meeting => meeting.is_active = false);
        }
    },
    actions: {
        requestAllMeetings: ({ commit }) => axios.get("/meeting/all").then(
            ({ data: { current, all } }) => commit("setAllMeetings", { current, all })
        ),
        requestCurrentMeeting: ({ commit }) => axios.get("/meeting/current").then(
            ({ data: { current } }) => commit("setCurrentMeeting", { current })
        ),
        requestCurrentMeetingResulstExcelLink: ({ commit }) => axios.get("/respond/excel").then(
            ({ data: { file } }) => commit("setCurrentMeetingResulstExcelLink", { file })
        ),
        closeCurrentMeeting: ({ commit }) => axios.get("/meeting/close").then(
            () => commit("setMeetingClose")
        ),
    }
};
