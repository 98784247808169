import Vue from "vue";
import router from "@/router";
import axios from "axios";

function authInitialState() {
    let storageUser = localStorage.getItem("user");

    if (storageUser) {
        return {
            user: JSON.parse(storageUser),
        };
    }

    return {
        user: {},
    };
}

export default {
    state: () => authInitialState(),
    getters: {
        user: state => state.user,
        isAuthenticated: state => typeof state.user.id !== "undefined",
        isAdmin: state => state.user.admin,
    },
    mutations: {
        setUser(state, user) {
            Vue.set(state, "user", user);
            localStorage.setItem("user", JSON.stringify(user));
        },
        clearUser(state) {
            localStorage.removeItem("user");
            Object.assign(state, authInitialState());
        }
    },
    actions: {
        login: ({ commit }, { username: login, password: pass }) => axios.post("/users/auth", { login, pass }).then(
            ({ data: { users } }) => commit("setUser", users[0])
        ),
        logout: ({ commit }) => {
            commit("resetMeetingsState");
            commit("resetUsersState");
            commit("resetCasesState");
            commit("resetCaseItemState");
            commit("clearUser");
            router.push("/login");
        }
    }
};
