import axios from "axios";

function meetingDraftInitialState() {
    let meetingDraftFromLS = getMeetingDraftFromLS();

    if (meetingDraftFromLS) {
        return JSON.parse(meetingDraftFromLS);
    }

    return {
        title: null,
        questions: [""]
    };
}

function getMeetingDraftFromLS() {
    return localStorage.getItem("meetingDraft");
}

function saveMeetingDraftToLS(meetingDraft) {
    localStorage.setItem("meetingDraft", JSON.stringify(meetingDraft));
}

function removeMeetingDraftFromLS() {
    localStorage.removeItem("meetingDraft");
}

export default {
    state: () => meetingDraftInitialState(),
    mutations: {
        setMeetingDraftTitle(state, title) {
            state.title = title;
            saveMeetingDraftToLS(state);
        },
        setMeetingDraftQuestion(state, { index, value }) {
            state.questions[index] = value;
            saveMeetingDraftToLS(state);
        },
        addMeetingDraftQuestion(state) {
            state.questions.push("");
            saveMeetingDraftToLS(state);
        },
        removeMeetingDraftQuestion(state, index) {
            state.questions.splice(index, 1);
            saveMeetingDraftToLS(state);
        },
        resetMeetingDraftState(state) {
            removeMeetingDraftFromLS();
            Object.assign(state, meetingDraftInitialState());
        }
    },
    actions: {
        addMeetingToDB: ({ state: { title: name, questions: issue } }) => axios.post(
            "/meeting/add",
            { name, issue }
        )
    }
};
