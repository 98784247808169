import Vue from "vue";
import axios from "axios";

function surveyDraftInitialState() {
    let surveyDraftFromLS = localStorage.getItem("surveyDraft");

    if (surveyDraftFromLS) {
        return JSON.parse(surveyDraftFromLS);
    }

    return {
        ids: [],
        byId: {}
    };
}

function saveSurveyDraftToLS(surveyDraft) {
    localStorage.setItem("surveyDraft", JSON.stringify(surveyDraft));
}

function removeSurveyDraftFromLS() {
    localStorage.removeItem("surveyDraft");
}

export default {
    state: () => surveyDraftInitialState(),
    mutations: {
        setSurveyDraft(state, surveyDraft) {
            state.ids.push(surveyDraft.id);
            state.byId[surveyDraft.id] = surveyDraft;
            saveSurveyDraftToLS(state);
        },
        setSurveyDraftRespond(state, { meetingId, id, value }) {
            let respond = state.byId[meetingId]?.respond?.find(item => {
                return item.id == id;
            });

            if (respond) {
                respond.val = value;
            }

            saveSurveyDraftToLS(state);
        },
        resetSurveyDraftState(state) {
            removeSurveyDraftFromLS();
            Object.assign(state, surveyDraftInitialState());
        }
    },
    actions: {
        submitRespondToDB: ({ commit, state, rootState }) => {
            let id_meeting = rootState.meetings?.current?.id;
            let id_user = rootState.auth?.user?.id;
            let respond = state.byId[id_meeting].respond.map(respond => ({
                id_issue: respond.id,
                val: respond.val
            }));

            // console.log({ id_meeting, id_user, respond });

            return axios.post("/respond/set", { id_meeting, id_user, respond });
        }
    }
};
