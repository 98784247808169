<template>
<div id="app">
    <sro-header class="app__header"/>
    <router-view class="app__main" :class="appMainClass"/>
    <icons class="app__icons"/>
    <messages class="app__messages"/>
</div>
</template>

<script>
import SroHeader from "@/components/SroHeader";
import Icons from "@/assets/icons.svg";
import Messages from "@/components/Messages";

export default {
    name: "App",
    components: {
        SroHeader,
        Icons,
        Messages,
    },
    computed: {
        appMainClass() {
            return `app__main_${this.$route.name?.toLowerCase()}`;
        },
    }
}
</script>

<style lang="scss">
#app {
    height: 100%;
    display: grid;
}
.app__header {
    margin: 0 auto;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 990;
}
.app__main {
    margin-top: var(--header-height);

    &_login {
        height: 100dvh;
        margin: 0 auto;
    }
}
.app__icons {
    display: none;
}
.app__messages {
    position: fixed;
    bottom: 20px;
    right: 40px;
}
</style>
