import Vue from "vue";
import router from "@/router";
import axios from "axios";

function usersInitialState() {
    return {
        all: [],
        ids: [],
        byId: {}
    };
}

export default {
    state: () => usersInitialState(),
    mutations: {
        setUsers(state, users) {
            Vue.set(state, "all", users);

            state.ids = users?.map(user => user.id);

            users?.forEach(user => {
                Vue.set(state.byId, user.id, user);
            });
        },
        deleteUserFromState(state, userToDeleteId) {
            state.ids = state.ids.filter(id => id !== userToDeleteId);
            delete state.byId[userToDeleteId];
        },
        updateUserInState(state, user) {
            state.byId[user.id] = {
                ...user
            };
        },
        addUserToState(state, user) {
            state.ids.push(user.id);
            state.byId[user.id] = user;
        },
        resetUsersState(state) {
            Object.assign(state, usersInitialState());
        }
    },
    actions: {
        requestAllUsers: ({ commit }) => axios.get("/users/get").then(
            ({ data: { users } }) => commit("setUsers", users)
        ),
        deleteUserFromDB: ({ commit }, id) => axios.post("/users/del", { id_user: id }).then(
            () => commit("deleteUserFromState", id)
        ),
        updateUserInDB: ({ commit }, updates) => axios.post("/users/set", updates).then(
            ({ data: { users }}) => {
                commit("updateUserInState", users[0]);
            }
        ),
        addUserToDB: ({ commit }, userData) => axios.post("/users/add", userData).then(
            ({ data: { users }}) => {
                commit("addUserToState", users[0]);
            }
        ),
    }
};` `
