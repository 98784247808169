<template>
<img class="spinner" src="/img/spinner.svg" alt=""/>
</template>

<script>
export default{
    name: "Spinner"
}
</script>

<style lang="scss">
.spinner {
    display: block;
    width: 128px;
    height: 128px;
}
</style>
