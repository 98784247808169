let locale = "ru-RU";

export let { format: intFormat } = new Intl.NumberFormat(locale, {
    maximumFractionDigits: 0
});

export let { format: floatFormat } = new Intl.NumberFormat(locale, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
});

export let { format: currencyIntFormat } = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: "RUB",
    currencyDisplay: "symbol",
    maximumFractionDigits: 0
});

export let { format: currencyFloatFormat } = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: "RUB",
    currencyDisplay: "symbol",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
});

export function yesOrNo(value) {
    return value ? "Да" : "Нет";
}
