Vue.config.productionTip = false;

/* Настройки axios */
import axios from "axios";
axios.defaults.baseURL = process.env.VUE_APP_API_URL;

/* Стили */
import "./styles/common.scss";

/* Глобальные фильтры */
import * as filters from "./helpers/filters";
Object.entries(filters).forEach(([filterName, filterFn]) => Vue.filter(filterName, filterFn));

/* Глобальные компоненты */
import Icon from "./components/Icon";
Vue.component("icon", Icon);

import Control from "./components/Control";
Vue.component("control", Control);

import SroButton from "./components/SroButton";
Vue.component("SroButton", SroButton);

import Spinner from "@/components/Spinner";
Vue.component("spinner", Spinner);

import ErrorMessage from "@/components/ErrorMessage";
Vue.component("error-message", ErrorMessage);


/* Глобальные сторонние компоненты */
import Popper from "vue-popperjs";
import "vue-popperjs/dist/vue-popper.css";
Vue.component("popper", Popper);

import VCalendar from "v-calendar";
Vue.use(VCalendar, {
    locales: {
        "ru": {
            masks: { weekdays: "WW" }
        }
    }
});

import VModal from "vue-js-modal";
Vue.use(VModal);


/* Main Vue Instance */
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { mapMutations } from "vuex";

new Vue({
    router,
    store,
    render: h => h(App),
    methods: {
        ...mapMutations([
            "setVersion",
        ]),
        getVersion({ matches: isDesktop }) {
            return isDesktop ? "desktop" : "mobile";
        }
    },
    created() {
        let desktopMinWidth = 1440;
        let desktopMediaQuery = window.matchMedia(`(min-width: ${desktopMinWidth}px)`);
        this.setVersion(this.getVersion(desktopMediaQuery));

        desktopMediaQuery.addEventListener("change", event => {
            this.setVersion(this.getVersion(event));
        });
    }
}).$mount("#app");
