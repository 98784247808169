<template>
<component :is="homeComponent"/>
</template>

<script>
import { mapGetters } from "vuex";
import Cases from "@/views/Cases.vue";
// import Stuff from "@/views/Stuff.vue";
import Meetings from "@/views/Meetings.vue";

export default {
    name: "Home",
    computed: {
        ...mapGetters(["user"]),
        homeComponent() {
            if (this.user?.admin) {
                return Meetings;
            }

            return Cases;
        }
    },
}
</script>

<style lang="scss">
.home {
    height: calc(100% - var(--header-height));
    padding: 40px 0;
    overflow: hidden;
}
.home__container {
    height: 100%;
    display: grid;
    grid-template-rows: auto 1fr;
    gap: 20px;
}
.home__heading {
    font-family: var(--garamond);
    font-weight: 500;
    font-size: 42px;
    line-height: 1.3;
}
.home__content {
    display: grid;
    gap: 20px;
    grid-template-rows: auto 1fr;
    padding: 32px 32px 10px;
    background-color: var(--white);
    border-radius: var(--border-radius);
    overflow: hidden;
}
.home__table-wrap {
    padding-right: 4px;
    overflow: auto;

    &::-webkit-scrollbar {
        width: 8px;
        background-color: transparent;
    }
    &::-webkit-scrollbar-track {
        border-width: 0;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 6px;
        background-color: var(--gray-300);
    }
}
.home__table {
    width: 100%;
}
.home__table-row {
    cursor: pointer;

    &:nth-of-type(odd) {
        background-color: var(--gray-200);
    }

    &_body {
        border-radius: var(--border-radius);
        transition: transform 150ms ease-in-out;

        &:hover {
            transform: translateY(-4px);
            outline: 2px solid var(--green-primary);
            outline-offset: -2px;
        }
    }
}
.home__table-cell {
    font-size: 14px;
    padding: 20px 8px;
    text-align: left;

    &_head {
        background-color: var(--white);
        font-weight: normal;
        font-size: 13px;
        color: var(--gray-400);

        position: sticky;
        top: 0;
    }
}
.home__notification {
    width: 530px;
    border-radius: calc(2 * var(--border-radius));
    padding: 24px;

    color: var(--white);

    position: fixed;
    left: 50%;
    bottom: 20px;
    background-color: var(--green-primary);
    transform: translateX(-50%);
}
.home__notification-title {
    font-weight: 500;
    font-size: 24px;
}
.home__notification {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    gap: 10px;
}

</style>
