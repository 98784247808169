<template>
<header class="header">
    <div class="header__container container">
        <router-link class="header__brand" :to="{ name: 'Home' }">
            <img class="header__logo"
                src="/img/logo.png"
                alt="Логотип СРО «Единство»"
                width="54"
                height="54"
            >
            <span class="header__title">Единство</span>
        </router-link>
        <div class="header__admin" v-if="isAuthenticated && user.admin">
            <router-link class="header__admin-link" :to="{ name: 'Home' }" exactActiveClass="header__admin-link_active">
                Собрания
            </router-link>
            <router-link class="header__admin-link" :to="{ name: 'Users' }" exactActiveClass="header__admin-link_active">
                Пользователи
            </router-link>
        </div>
        <div class="header__user" v-if="isAuthenticated" >
            <popper class="header__notifications"
                trigger="clickToOpen"
                :options="{
                    placement: 'bottom',
                    modifiers: { offset: { offset: '-120px, 5px' } }
                }"
            >
                <button class="header__notifications-button" slot="reference">
                    <icon class="header__notifications-icon" icon="bell"/>
                </button>
                <div class="header__popup header__popup_notifications">
                    <p>Новых уведомлений нет</p>
                </div>
            </popper>

            <popper class="header__user-profile"
                trigger="clickToOpen"
                :options="{
                    placement: 'bottom',
                    modifiers: { offset: { offset: '0, 5px' } }
                }"
            >
                <img class="header__user-img"
                    slot="reference"
                    :src="user.img"
                    :alt="user.short"
                    width="48"
                    height="48"
                >
                <div class="header__popup header__popup_user">
                    <span>{{ user.short }}</span>
                    <button class="header__user-logout" @click="logout">Выйти</button>
                </div>
            </popper>
        </div>
    </div>
</header>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";

export default {
    name: "SroHeader",
    computed: {
        ...mapState({
            title: state => state.page.title,
            meeting: state => state.meetings.current,
        }),
        ...mapGetters([
            "isAuthenticated",
            "user"
        ]),
        isMeetingPage() {
            return this.$route.name === "Meeting";
        },
        isMeeting() {
            return this.meeting?.is_active;
        },
    },
    methods: {
        ...mapActions(["logout"])
    }
}
</script>

<style lang="scss">
.header {
    display: grid;
    align-items: center;
    max-width: var(--html-max-width);
    height: var(--header-height);

    padding: 8px 0;
    background-color: var(--white);
}
.header__container {
    display: grid;
    grid-auto-flow: column;
    justify-content: space-between;
    align-items: center;
}
.header__brand {
    display: grid;
    grid-auto-flow: column;
    justify-content: start;
    align-items: center;
    grid-gap: 12px;
}
.header__logo {
    width: 48px;
    height: 48px;
}
.header__title {
    font-family: var(--garamond);
    font-weight: 500;
    font-size: 27px;
    line-height: 1;
}
.header__user {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    gap: 40px;
}
.header__user-profile {
    display: grid;
}
.header__user-img {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    cursor: pointer;
}
.header__popup {
    background-color: var(--white);
    padding: 15px 20px;
    border-radius: var(--border-radius);
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);

    & .popper__arrow {
        display: none;
    }

    &_notifications {
        width: 300px;
        min-height: 200px;
        padding: 20px 10px;
        display: grid;
        place-items: center;
        text-align: center;
    }

    &_user {
        display: grid;
        place-items: center;
        gap: 8px;
        text-align: center;
    }
}
.header__user-logout {
    color: var(--green-200);
    text-decoration: underline;

    transition: color 100ms ease-in-out;

    &:hover {
        color: var(--black);
    }
}
.header__notifications-button {
    display: grid;
    place-items: center;
    padding: 8px;
}
.home__notification-link {
    &:hover {
        background-color: #60a476;
    }
}
.header__admin {
    display: inline-grid;
    grid-auto-flow: column;
    grid-gap: 24px;
}
.header__admin-link {
    &_active {
        font-weight: bold;
    }
}
</style>
