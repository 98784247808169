import Vue from "vue";
import Vuex from "vuex";

import page from "./page";
import ui from "./ui";
import auth from "./auth";
import users from "./users";
import meetingDraft from "./meetingDraft";
import meetings from "./meetings";
import surveyDraft from "./surveyDraft";
import cases from "./cases";
import caseItem from "./caseItem";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        page,
        ui,
        auth,
        users,
        meetingDraft,
        meetings,
        surveyDraft,
        cases,
        caseItem
    }
});
