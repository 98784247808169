<template>
<main class="meetings">
    <div class="meetings__container container">
        <h1 class="meetings__heading">Собрания</h1>
        <div class="meetings__content">
            <spinner class="meetings__spinner" v-if="status === 'loading'"/>
            <error-message class="meetings__error-message" v-else-if="status === 'error'">
                Не удалось загрузить данные
            </error-message>
            <div class="meetings__data" v-else>
                <div class="meetings__summary">
                    <p class="meetings__total">Всего собраний: {{ meetings.length }}</p>
                    <router-link v-if="!isMeeting" :to="{ name: 'Meeting' }"
                        class="button button_outline meetings__button meetings__button_create-meeting"
                    >
                        Создать собрание
                    </router-link>
                </div>
                <div class="meetings__table-wrap">
                    <table class="meetings__table">
                        <thead>
                            <tr class="meetings__table-row">
                                <th class="meetings__table-cell meetings__table-cell_head">Название</th>
                                <th class="meetings__table-cell meetings__table-cell_head">Активно</th>
                                <th class="meetings__table-cell meetings__table-cell_head">Вопросов</th>
                                <th class="meetings__table-cell meetings__table-cell_head">Участников</th>
                                <th class="meetings__table-cell meetings__table-cell_head">Прошли</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="meetings__table-row meetings__table-row_body"
                                v-for="meeting in meetings" :key="meeting.id"
                                @click.prevent="handleMeetingClick(meeting.id)"
                            >
                                <td class="meetings__table-cell">
                                    {{ meeting.short }}
                                </td>
                                <td class="meetings__table-cell">
                                    {{ meeting.is_active | yesOrNo}}
                                </td>
                                <td class="meetings__table-cell">
                                    {{ meeting.issue.length}}
                                </td>
                                <td class="meetings__table-cell">
                                    {{ meeting.users.length }}
                                </td>
                                <td class="meetings__table-cell">
                                    {{ responsesCount(meeting) }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</main>
</template>5

<script>
import { mapActions, mapState } from "vuex";

export default {
    name: "Meetings",
    data() {
        return {
            status: "loading",
        };
    },
    computed: {
        ...mapState({
            meetings: state => state.meetings?.all,
            currentMeeting: state => state.meetings.current,
        }),
        isMeeting() {
            return this.currentMeeting?.is_active;
        },
    },
    methods: {
        ...mapActions([
            "requestAllMeetings",
        ]),
        showData() {
            this.status = "success";
        },
        showError() {
            this.status = "error";
        },
        loadData() {
            return Promise.all([
                this.requestAllMeetings(),
                new Promise(resolve => setTimeout(resolve, 250))
            ]);
        },
        responsesCount(meeting) {
            let responses = meeting?.users?.reduce((result, { is_respond }) => result + Number(is_respond), 0);

            if (responses === 0) {
                return responses;
            }

            return `${responses} (${((responses / meeting?.users?.length) * 100).toFixed(2)}%)`;
        },
        handleMeetingClick(meetingId) {
            this.$router.push({
                name: "MeetingItem",
                params:{ meetingId }
            });
        }
    },
    created() {
        if (this.meetings?.length > 0) {
            this.showData();
        } else {
            this.loadData().then(this.showData, this.showError);
        }
    }
}
</script>

<style lang="scss">
.meetings__container {
    height: 100%;
    display: grid;
    grid-template-rows: auto 1fr;
    gap: 10px;
    padding: 20px 0;
}
.meetings__heading {
    font-family: var(--garamond);
    font-weight: 500;
    font-size: 36px;
    line-height: 1.3;
}
.meetings__heading-2 {
    font-family: var(--garamond);
    font-weight: 500;
    font-size: 28px;
    line-height: 1.3;
}
.meetings__content {
    display: grid;
    background-color: var(--white);
    border-radius: var(--border-radius);
    padding: 24px;
}
.meetings__spinner {
    place-self: center;
}
.meetings__error-message {
    place-self: center;
}
.meetings__data {
    display: grid;
    grid-gap: 8px;
    align-content: start;
}
.meetings__table-wrap {
    padding-right: 4px;
    overflow: auto;

    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: transparent;
    }
    &::-webkit-scrollbar-track {
        border-width: 0;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 6px;
        background-color: var(--gray-300);
    }
}
.meetings__table {
    width: 100%;
}
.meetings__table-row {

    &:nth-of-type(odd) {
        background-color: var(--gray-200);
    }

    &_body {
        border-radius: var(--border-radius);
        transition: transform 150ms ease-in-out;
        cursor: pointer;

        &:hover {
            transform: translateY(4px);
            outline: 2px solid var(--green-primary);
            outline-offset: -2px;
        }
    }
}
.meetings__table-cell {
    font-size: 14px;
    padding: 20px 8px;
    text-align: left;

    &_head {
        background-color: var(--white);
        font-weight: normal;
        font-size: 13px;
        color: var(--gray-400);

        position: sticky;
        top: 0;
        z-index: 1;
    }

    &_body {
        white-space: nowrap;
    }
}
.meetings__summary {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
}
</style>
