import formatDate from "date-fns/format";
import parseISO from "date-fns/parseISO";
import ru from "date-fns/locale/ru";

export function dateText(date) {
    return date instanceof Date ? formatDate(date, "dd.MM.yyyy") : "";
}

export function dateTextLong(date) {
    return date instanceof Date ? formatDate(date, "d MMMM yyyy г.", { locale: ru }) : "";
}

export function datetimeText(date) {
    return date instanceof Date ? formatDate(date, "dd.MM.yyyy г. hh:mm:ss", { locale: ru }) : "";
}

export function dateMonthYearText(date) {
    return date instanceof Date ? formatDate(date, "LLLL yyyy г.", { locale: ru }) : "";
}
