import Vue from "vue";
import axios from "axios";
import parseISO from "date-fns/parseISO";

function casesInitialState() {
    return {
        casesBySearch: {
            default: {
                list: [],
                currentPage: 0,
                pagesTotalCount: null,
            }
        },
        nomenclature: {}
    };
}

export default {
    state: () => casesInitialState(),
    mutations: {
        setCasesParameter(state, { parameter, value }) {
            state[parameter] = value;
        },
        setCasesBySearchDefaults(state, searchKey) {
            Vue.set(state.casesBySearch, searchKey, {
                list: [],
                currentPage: 0,
                pagesTotalCount: null,
            });
        },
        setCasesBySearch(state, { searchKey, data: { case_list, num_page } }) {
            Vue.set(state.casesBySearch[searchKey], "list", case_list);
            state.casesBySearch[searchKey].pagesTotalCount = num_page;
        },
        setCasesPageBySearch(state, { searchKey, currentPage }) {
            state.casesBySearch[searchKey].currentPage = currentPage;
        },
        resetCasesState(state) {
            Object.assign(state, casesInitialState());
        },
        setNomenclature(state, nomenclature) {
            Vue.set(state.nomenclature, "INK_INFO", nomenclature["Сведения о ходе реализации"]);
            Vue.set(state.nomenclature, "AUCTION", nomenclature["Торги"]);
            Vue.set(state.nomenclature, "REGIONS", nomenclature["Регионы"]);
            Vue.set(state.nomenclature, "PROPERTY_TYPES", nomenclature["Виды имущества"]);
        },
    },
    actions: {
        requestCases: ({ commit, state, rootState }, { searchTerm, searchKey }) => {
            let currentPage = state.casesBySearch?.[searchKey]?.currentPage ?? 0;
            let params = {
                user_id: rootState.auth.user.id
            };

            if (searchTerm !== "") {
                params.search = searchTerm;
            }

            return axios.get(`/case/get/${currentPage}`, { params }).then(
                ({ data }) => {
                    data.case_list.forEach(case_item => {
                        [
                            "PROTOCOL_DATE",
                            "LETTER_DATE",
                            "COURT_DATE_SK",
                            "K_BALANCE_DATE",
                            "CHANGE_PROCEDURE"
                        ].forEach(field => {
                            let date = parseISO(case_item[field]);
                            case_item[field] = date.getFullYear() === 1 ? null : date;
                        })
                    });

                    commit("setCasesBySearch", { searchKey, data });
                }
            );
        },
        requestNomenclature: ({ commit }) => axios.get("/case/nomenclature").then(
            ({ data: { nomenclature } }) => {
                commit("setNomenclature", nomenclature);
            }
        ),
    }
};
