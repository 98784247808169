<template>
<label class="control">
    <span class="control__field">
        <icon v-if="icon && iconPrepend" class="control__icon" :icon="icon"/>
        <input class="control__input"
            :type="currentType"
            :placeholder="label"
            v-model="model"
            :autocomplete="autocomplete"
        >
        <span class="control__label">{{ label }}</span>
        <button v-if="type === 'password'"
            type="button"
            class="control__toggle-password"
            @click="togglePassword"
        >
            <icon class="control__icon" :icon="passwordIcon"/>
        </button>
        <icon v-else-if="icon && !iconPrepend" class="control__icon" :icon="icon"/>
    </span>
    <span v-if="error" class="control__error">{{ error }}</span>
</label>
</template>

<script>
export default {
    name: "Control",
    props: {
        type: {
            type: String,
            default: "text"
        },
        value: {
            type: String,
            required: true
        },
        label: {
            type: String,
            required: true
        },
        error: {
            type: String
        },
        icon: {
            type: String
        },
        autocomplete: {
            type: String
        },
        iconPrepend: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            currentType: this.type
        };
    },
    computed: {
        model: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            }
        },
        passwordIcon() {
            return this.currentType === "password" ? "eye-closed" : "eye-opened";
        }
    },
    methods: {
        togglePassword() {
            if (this.currentType === "password") {
                this.currentType = "text"
            } else {
                this.currentType = "password"
            }
        }
    }
}
</script>

<style lang="scss">
.control {
    display: grid;
    gap: 4px;
    min-width: 240px;

}
.control__field {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    gap: 6px;
    align-items: center;
    border: 1px solid var(--gray-300);
    padding: 10px 16px;
    background-color: var(--white);
    border-radius: var(--border-radius);
    position: relative;
    transition: border-color 100ms ease-in-out;

    &:focus-within {
        --icon-color: var(--green-200);
        border-color: var(--green-200);
    }
}
.control__input {
    flex-grow: 1;
    display: inline-block;
    padding-top: 14px;

    font-size: 15px;
    line-height: 20px;

    &::placeholder {
        color: transparent;
    }
}
.control__label {
    display: grid;
    place-items: center;
    height: 100%;

    color: var(--gray-400);
    font-size: 15px;
    line-height: 20px;

    position: absolute;
    top: 0;
    left: 16px;
    z-index: 2;

    transform-origin: 0 0;
    transition: transform 100ms ease-in-out;

    .control__icon ~ & {
        left: 42px;
    }

    .control__input:focus + &,
    .control__input:not(:placeholder-shown) + & {
        transform: scale(0.85) translateY(-9px);
    }
}
.control__toggle-password {
    display: grid;
    place-items: center;
    padding: 6px;
    margin-right: -6px;
}
.control__icon {
    --icon-size: 20px;
}
.control__error {
    font-size: 12px;
    color: var(--red-error);
}
.control__clear-search {
    display: grid;
    cursor: pointer;
    padding: 6px;
    margin-right: -6px;
}
.control__clear-search-icon {
    --icon-size: 12px;
    --icon-color: var(--black);
}
</style>
